.table {
  width: 85%;
  margin-top: 40px;
  margin-left: 20px;
  border: none;

  box-shadow: 0px 8px 20px rgba(109, 141, 173, 0.4);
  background: #ffffff;
  padding: 40px 20px;
}

.Add_btn {
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 30px;
  padding: 15px 40px;
  margin: auto;
  display: flex;
  transition: 0.2s all ease-in;
}
.Add_btn:hover {
  background-color: transparent;
  color: black;
}
.fomr_parent {
  border-radius: 12px;
  background: #ffffff;
  padding: 40px 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  transition: 0.6s all ease-in-out;
  border: 1px solid;
}
.fomr_parent:hover {
  transform: translateY(-0.413rem);
  box-shadow: 0px 8px 20px rgba(109, 141, 173, 0.4);
}

tr:hover {
  background-color: #dadada;
}
th {
  padding: 20px !important;
}
tr {
  border: none;
}
td {
  border: 1px solid rgba(213, 213, 213, 0.441) !important;
}
.edit_btn {
  background-color: rgb(25, 181, 25);
  color: white;
  border: 1px solid rgb(25, 181, 25);
  border-radius: 40px;
  padding: 10px 20px;
  margin: auto;
  display: flex;
  transition: 0.2s all ease-in;
}
.edit_btn:hover {
  background-color: transparent;
  color: rgb(25, 181, 25);
}

.delete_btn {
  background-color: rgb(181, 25, 25);
  color: white;
  border: 1px solid rgb(181, 25, 25);
  border-radius: 40px;
  padding: 10px 20px;
  margin: auto;
  display: flex;
  transition: 0.2s all ease-in;
}

.delete_btn:hover {
  background-color: transparent;
  color: rgb(181, 25, 25);
}
input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 1.575rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 4px 12px rgb(109 141 173 / 12%) !important;
  border-radius: 8px !important;
}

.main_heading {
  word-wrap: anywhere;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 158px;
  line-height: 128px;
  letter-spacing: -0.02em;
  color: #262626;
  margin-bottom: 30px;
  transition: all 0.5s ease;
  transform: translate(0px, 100%);
  text-align: left;
  height: 185px;
}
.main:hover .main_heading {
  transform: unset !important;
  line-height: 64px !important;
  font-size: 64px !important;
  text-align: center !important;
  height: 70px;
}
.main {
  transition: all 0.5s ease 0s;
  padding-top: 150px;
}
.main:hover {
  transition: all 0.5s ease 0s;
  /* padding-top: 120px; */
  padding-top: 30px;
}
.row {
  opacity: 0;
  position: relative;
  bottom: -430px;
  transition: all 0.5s ease;
}

.main:hover .row {
  opacity: 1 !important;
  position: relative !important;
  bottom: 0 !important;
}
